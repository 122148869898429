import { defineStore } from 'pinia';
import Cookies from 'js-cookie';
// import jwt from 'jsonwebtoken';
import jwtDecode from 'jwt-decode';

type AuthStateType = {
    authenticated: boolean;
    token: string;
    jwt: any;
};

const getDefaultAppState = (): AuthStateType => {
    return {
        authenticated: false,
        token: '',
        jwt: {},
    };
};

// https://pinia.vuejs.org/core-concepts/#setup-stores
export const useAuthStore = defineStore('woom-store-auth', {
    state: () => getDefaultAppState(),
    actions: {
        /**
         * TODO @bruce
         * the api never sets the session token as a cookie!!!!
         * should be returned in the response headers of the api
         */
        setToken({ token }: { token: string }) {
            // console.log('authStore.setToken', token);
            const config = useRuntimeConfig();

            if (token && import.meta.client) {
                const jwt = jwtDecode(token);
                this.jwt = jwt;
                this.token = token;
                Cookies.set('woomsession', token, { expires: 60, path: '/', secure: config.public.nodeEnv === 'production' });
            }
        },
        // check token is called on each transition, read from cookie
        checkToken() {
            if (import.meta.client) {
                const v = Cookies.get('woomsession');
                if (v) {
                    if (this.token !== v) {
                        // const jwt = await require('jsonwebtoken')
                        this.token = v;
                        this.jwt = jwtDecode(v);
                    }
                    return v;
                }
            }
            return '';
        },
    },

    // const getters = {
    //     authenticated() {
    //         return state.value.authenticated;
    //     },
    // };
});

export type AuthStoreType = ReturnType<typeof useAuthStore>;

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
