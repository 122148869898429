import type { RouterConfig } from '@nuxt/schema';

// 📘 https://nuxt.com/docs/guide/going-further/custom-routing#router-options
export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        const app = useNuxtApp();
        // close side drawer and menu when navigation happens
        app.$eventEmitter.emit('on-navigation');

        // don't scroll when path stays the same and variant changes (BundleSelector)
        if (from.path === to.path && to.query.variant && to.query.variant !== from.query.variant) return;
        // don't scroll when variant stays the same and path changes (BundleSelector)
        if (to.query.variant && to.query.variant === from.query.variant) return;

        // on product page when the url has variant and #reviews for stamped, we should not scroll on variant change by clicking color selection.
        if (from.path === to.path && to.hash && to.hash === from.hash) return;

        if (to?.hash) {
            return {
                el: to.hash,
                top: 32,
                behavior: 'smooth',
            };
        }
        // if link is to same page, scroll to top with smooth behavior
        if (to === from) {
            return {
                left: 0,
                top: 0,
                behavior: 'smooth',
            };
        }

        // this will use saved scroll position on browser forward/back navigation
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    left: savedPosition?.left || 0,
                    top: savedPosition?.top || 0,
                });
            }, 500);
        });
    },
};
