type ScrollSubscriber = {
    callback: () => void;
    once?: boolean;
};

export type GlobalScroll = {
    disable: (disable: Ref<any>) => void;
    subscribe: (subscriber: ScrollSubscriber) => () => void;
    isScrolled: Ref<boolean>;
};

let scrollTimeout: ReturnType<typeof setTimeout>;
let lastEventTime: number = 0;
let scrollSubscribers: ScrollSubscriber[] = [];

const disable: GlobalScroll['disable'] = (disable) => {
    useHead({
        bodyAttrs: {
            class: computed(() => (disable.value ? 'overflow-hidden' : '')),
        },
    });
};

const handleScrollEvent = () => {
    const nowTime = new Date().getTime();
    // fire event at least every 300ms
    if (nowTime - lastEventTime < 200) clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
        lastEventTime = new Date().getTime();
        for (const subscriber of scrollSubscribers) {
            subscriber.callback();
            if (subscriber.once) removeSubscriber(subscriber);
        }
    }, 100);
};

const removeSubscriber = (subscriber: ScrollSubscriber) => {
    scrollSubscribers = scrollSubscribers.filter(({ callback }) => subscriber.callback !== callback);
};

const subscribe: GlobalScroll['subscribe'] = (subscriber) => {
    if (scrollSubscribers.length === 0) {
        window.addEventListener('scroll', handleScrollEvent, { capture: true, passive: true });
    }
    scrollSubscribers.push(subscriber);

    return () => removeSubscriber(subscriber);
};

export default defineNuxtPlugin((nuxtApp) => {
    const isScrolled = ref<boolean>(false);

    const appStore = useAppStore();

    subscribe({
        callback: () => {
            isScrolled.value = window.scrollY > 0;
            appStore.menuOffset = window.scrollY < 48;
        },
    });
    useHead({
        bodyAttrs: {
            class: computed(() => (isScrolled.value ? 'scrolled' : '')),
        },
    });

    const scroll: GlobalScroll = {
        disable,
        subscribe,
        isScrolled,
    };

    nuxtApp.provide('scroll', scroll);
});
