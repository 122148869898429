//This plugin must run first - hence the name - it must be first alphabetically in the plugins folder
//https://nuxt.com/docs/guide/directory-structure/plugins#registration-order

//url parameters are stripped out when passing requests through the cloudflare cache worker
//the stripped url is then stored by nuxt in the response
//when hydrating this response, the stripped url is then applied in the window

//this code is to ensure that the original url is preserved

export default defineNuxtPlugin((nuxtApp) => {
    //get the actual url requested by the browser
    const requestURL = useRequestURL();

    //just to be 100% safe - only allow this to run once
    let enableRouteCancel = true;
    addRouteMiddleware((to, _from) => {
        if (!enableRouteCancel) {
            return true;
        }
        const routerTarget = to;

        const targetPath = routerTarget.path;
        const targetSearch = routerTarget.fullPath.replace(routerTarget.path, '');

        const requestPath = requestURL.pathname;
        const requestSearch = requestURL.search;

        //if the app is busy hydrating, path is the same as that was requested, but query string is shorter,
        //stop/cancel the route change - this is the exact case where we are losing query parameters when we hydrate
        //a cloudflare worker payload, that had query parameters stripped out
        if (nuxtApp.isHydrating && targetPath === requestPath && targetSearch.length < requestSearch.length) {
            enableRouteCancel = false;
            return false;
        } else {
            return true;
        }
    });
});
