// https://developer.helpscout.com/beacon-2/web/javascript-api/

const beaconMap: Record<string, string> = {
    // language specific
    de: 'ca4f752b-bbb7-4d16-8418-83b51e407763',
    it: '3bcd114a-ed1e-473a-b3bc-b1af295ae315',
    fr: '2f4831f6-1117-40f8-a6ad-456ad30a7db0',
    en: '94406789-edf1-40d0-80cc-b79b180732d6',

    // country specific
    // TODO: check where we have GB
    en_gb: '12e2ce8a-826e-46f3-8b3f-ffaf28b48d6c',
    en_us: '30b6db0e-0658-4d91-9b5f-d6d16cbadc36',
};

export default defineNuxtPlugin(() => {
    const nuxtApp = useNuxtApp();

    const initHelpscout = (beaconId: string) => {
        window.Beacon = Object.assign(
            (method: string, options: any, data?: any) => {
                window.Beacon.readyQueue.push({ method, options, data });
            },
            {
                readyQueue: [],
                config: {
                    display: {
                        zIndex: 40,
                        horizontalOffset: 32,
                        verticalOffset: 32,
                        horizontalMobileOffset: 24,
                        verticalMobileOffset: 24,
                    },
                },
            },
        );

        useHead({
            script: [
                {
                    src: 'https://beacon-v2.helpscout.net',
                    async: true,
                    onload: () => {
                        window.Beacon('init', beaconId);
                    },
                },
            ],
        });
    };

    watch(
        nuxtApp.$i18n.locale,
        (newLocale) => {
            if (!newLocale) return;
            const langCountrySplit = newLocale.split('_');
            const beaconId = beaconMap[newLocale.toLowerCase()] || beaconMap[langCountrySplit[0].toLowerCase()];

            if (!beaconId) return;
            // TODO: check lazy loading helpscout beacon on preview
            // initHelpscout(beaconId);
            nuxtApp.$scroll.subscribe({
                callback: () => initHelpscout(beaconId),
                once: true,
            });
        },
        { immediate: true },
    );
});
