
import * as noneRuntime$N7VIKBA3WT from '/usr/src/app/image-providers/passthrough.provider.ts'
import * as assetsRuntime$YcRElAi3xe from '/usr/src/app/image-providers/assets.provider.ts'
import * as mediahubRuntime$1E9BaObp1C from '/usr/src/app/image-providers/mediahub.provider.ts'
import * as kenticoRuntime$U7nVGNLKwr from '/usr/src/app/image-providers/kentico.provider.ts'
import * as shopifyRuntime$CNQKpZxqbn from '/usr/src/app/image-providers/shopify.provider.ts'
import * as ipxRuntime$X2gfuDLzuS from '/usr/src/app/node_modules/.pnpm/@nuxt+image@1.4.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.19.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 300,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1264,
    "xxl": 1536,
    "2xl": 1440
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "jpg",
    "webp"
  ]
}

imageOptions.providers = {
  ['none']: { provider: noneRuntime$N7VIKBA3WT, defaults: undefined },
  ['assets']: { provider: assetsRuntime$YcRElAi3xe, defaults: {} },
  ['mediahub']: { provider: mediahubRuntime$1E9BaObp1C, defaults: {} },
  ['kentico']: { provider: kenticoRuntime$U7nVGNLKwr, defaults: {} },
  ['shopify']: { provider: shopifyRuntime$CNQKpZxqbn, defaults: {} },
  ['ipx']: { provider: ipxRuntime$X2gfuDLzuS, defaults: {} }
}
        