interface ToastProps {
    position: string;
    message: {
        severity: 'success' | 'info' | 'warn' | 'error';
        summary: string;
        [key: string]: any;
    };
}

export default {
    root: () => {
        return {
            class: 'md:max-w-md z-3xl w-[calc(100%-40px)]',
        };
    },
    message: ({ props }: { props: ToastProps }) => {
        return {
            class: [
                'rounded-md text-white mt-2',
                {
                    'bg-woom-black': props.message.severity === 'info',
                    'bg-woom-green': props.message.severity === 'success',
                    'bg-woom-orange-flame': props.message.severity === 'warn',
                    'bg-woom-red': props.message.severity === 'error',
                },
            ],
        };
    },
};
