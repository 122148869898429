// import { OperationVariables } from '@apollo/client';
// eslint-disable-next-line import/named
//import type { AsyncData, KeysOf, PickFrom } from 'nuxt/app/composables/asyncData';

import type { AsyncData } from 'nuxt/app';

//KeysOf, PickFrom

import type { MutateResult } from '@vue/apollo-composable';
import type { OperationVariables } from '@apollo/client/core/types';
import type {
    BackInStockMutation,
    BackInStockMutationVariables,
    BackInStockSubmitMutation,
    BackInStockSubmitMutationVariables,
    BikefinderQuery,
    BikefinderQueryVariables,
    BundleQuery,
    BundleQueryVariables,
    BundlesQuery,
    BundlesQueryVariables,
    CheckoutMutation,
    CheckoutMutationVariables,
    CollectionQuery,
    CollectionQueryVariables,
    CrmSubmitMutation,
    CrmSubmitMutationVariables,
    FindMyBikeMutation,
    FindMyBikeMutationVariables,
    LanguageSwitcherQuery,
    LanguageSwitcherQueryVariables,
    MenuQuery,
    MenuQueryVariables,
    NotificationsQuery,
    NotificationsQueryVariables,
    PageByIdQuery,
    PageByIdQueryVariables,
    PageByUrlQuery,
    PageByUrlQueryVariables,
    ProductQuery,
    ProductQueryVariables,
    RelatedQuery,
    RelatedQueryVariables,
    ShopContentQuery,
    ShopContentQueryVariables,
    TeaserQuery,
    TeaserQueryVariables,
    WebsiteQuery,
    WebsiteQueryVariables,
} from '~/graphql/generated';

type URLParam = { key: string; value: string };

class URLParams {
    private params: URLParam[];
    constructor() {
        this.params = [];
    }

    private add(key: string, value: string) {
        this.params.push({ key, value });
    }

    // If value is null or undefined, do not add it
    addMaybe(key: string, param: string | boolean | undefined | object | null) {
        if (typeof param === 'boolean') {
            this.add(key, param.toString());
        } else if (typeof param === 'string') {
            this.add(key, param);
        } else if (typeof param === 'object') {
            this.add(key, encodeURIComponent(JSON.stringify(param)));
        }
    }

    static fromVariables(variables: any) {
        const output = new URLParams();
        const keys = Object.keys(variables);

        for (const key in keys) {
            const actualKey = keys[key];
            output.addMaybe(actualKey, variables[actualKey]);
        }
        return output;
    }

    toParamsString() {
        if (this.params.length === 0) {
            return '';
        }

        this.params.sort((a, b) => {
            return a.key < b.key ? -1 : 1;
        });

        const parametersString =
            '?' +
            this.params
                .map((param) => {
                    return `${param.key}=${param.value}`;
                })
                .join('&');

        return parametersString;
    }
}

// const apiCache: Record<string, any | undefined> = {};

const pendingPromises: Record<string, Promise<any> | undefined> = {};

const getQueryData = async <Query, QueryVariables>(variables: QueryVariables, url: string): Promise<ExtendedApolloQueryResult<Query>> => {
    const appStore = useAppStore();
    const authStore = useAuthStore();

    const params = URLParams.fromVariables(variables);

    const fullURL = `${url}${params.toParamsString()}`;

    // check in cache
    // const cacheMatch = apiCache[fullURL];
    // if (typeof cacheMatch !== 'undefined') {
    //     return cacheMatch;
    // }

    // check for already running
    const pendingPromise = pendingPromises[fullURL];

    if (pendingPromise) {
        return (await pendingPromise)._data as any as ExtendedApolloQueryResult<Query>; // await pendingPromise;
    }
    try {
        const headers: any = {};
        try {
            if (process.server) {
                const clientIP = appStore.clientIP;
                if (clientIP && clientIP.length > 0) {
                    headers['x-forwarded-for'] = clientIP;
                }
            }
        } catch (e) {
            console.log('error getting clientip in getQueryData', e);
        }

        const promise = $fetch.raw(fullURL, {
            headers,
        });

        pendingPromises[fullURL] = promise;
        appStore.hasPendingPromises = true;

        const awaitResult = await promise;

        const token = awaitResult.headers.get(WoomCookies.WoomSession);
        if (token) {
            authStore.setToken({
                token,
            });
        }

        const data: ExtendedApolloQueryResult<Query> = awaitResult._data as any as ExtendedApolloQueryResult<Query>;

        // apiCache[url] = data;

        return data;
    } finally {
        delete pendingPromises[fullURL];
        appStore.hasPendingPromises = Object.keys(pendingPromises).length > 0;
    }
};

type UseQueryDataParameters<Variables, Query> = {
    variables: Variables;
    callback?: (result: ExtendedApolloQueryResult<Query> | null) => void;
    onErr?: (err: Error) => void;
};

const getWebsiteQueryData = async (variables: WebsiteQueryVariables): Promise<ExtendedApolloQueryResult<WebsiteQuery>> => {
    return await getQueryData(variables, '/api/website');
};

export const useWebsiteQueryData = (params: UseQueryDataParameters<WebsiteQueryVariables, WebsiteQuery>) => {
    return useQueryData({
        query: getWebsiteQueryData,
        ...params,
    });
};

const getMenuQueryData = async (variables: MenuQueryVariables): Promise<ExtendedApolloQueryResult<MenuQuery>> => {
    return await getQueryData(variables, '/api/menu');
};

export const useMenuQueryData = (params: UseQueryDataParameters<MenuQueryVariables, MenuQuery>) => {
    return useQueryData({
        query: getMenuQueryData,
        ...params,
    });
};

const getNotificationsQueryData = async (variables: NotificationsQueryVariables): Promise<ExtendedApolloQueryResult<NotificationsQuery>> => {
    return await getQueryData(variables, '/api/notifications');
};

export const useNotificationsQueryData = (params: UseQueryDataParameters<NotificationsQueryVariables, NotificationsQuery>) => {
    return useQueryData({
        query: getNotificationsQueryData,
        ...params,
    });
};

export const getPageByUrlQueryData = async (variables: PageByUrlQueryVariables): Promise<ExtendedApolloQueryResult<PageByUrlQuery>> => {
    return await getQueryData(variables, '/api/page-by-url');
};

export const usePageByUrlQueryData = (params: UseQueryDataParameters<PageByUrlQueryVariables, PageByUrlQuery>) => {
    return useQueryData({
        query: getPageByUrlQueryData,
        ...params,
    });
};

const getLanguageSwitcherQueryData = async (variables: LanguageSwitcherQueryVariables): Promise<ExtendedApolloQueryResult<LanguageSwitcherQuery>> => {
    return await getQueryData(variables, '/api/language-switcher');
};

export const useLanguageSwitcherQueryData = (params: UseQueryDataParameters<LanguageSwitcherQueryVariables, LanguageSwitcherQuery>) => {
    return useQueryData({
        query: getLanguageSwitcherQueryData,
        ...params,
    });
};

const getPageByIdQueryData = async (variables: PageByIdQueryVariables): Promise<ExtendedApolloQueryResult<PageByIdQuery>> => {
    return await getQueryData(variables, '/api/page-by-id');
};

export const usePageByIdQueryData = (params: UseQueryDataParameters<PageByIdQueryVariables, PageByIdQuery>) => {
    return useQueryData({
        query: getPageByIdQueryData,
        ...params,
    });
};

const getProductQueryData = async (variables: ProductQueryVariables): Promise<ExtendedApolloQueryResult<ProductQuery>> => {
    return await getQueryData(variables, '/api/product');
};

export const useProductQueryData = (params: UseQueryDataParameters<ProductQueryVariables, ProductQuery>) => {
    return useQueryData({
        query: getProductQueryData,
        ...params,
    });
};

const getCollectionQueryData = async (variables: CollectionQueryVariables): Promise<ExtendedApolloQueryResult<CollectionQuery>> => {
    return await getQueryData(variables, '/api/collection');
};

export const useCollectionQueryData = (params: UseQueryDataParameters<CollectionQueryVariables, CollectionQuery>) => {
    return useQueryData({
        query: getCollectionQueryData,
        ...params,
    });
};

const getBundlesQueryData = async (variables: BundlesQueryVariables): Promise<ExtendedApolloQueryResult<BundlesQuery>> => {
    return await getQueryData(variables, '/api/bundles');
};

export const useBundlesQueryData = (params: UseQueryDataParameters<BundlesQueryVariables, BundlesQuery>) => {
    return useQueryData({
        query: getBundlesQueryData,
        ...params,
    });
};

async function getBundleQueryData(variables: BundleQueryVariables): Promise<ExtendedApolloQueryResult<BundleQuery>> {
    return await getQueryData(variables, '/api/bundle');
}

export const useBundleQueryData = (params: UseQueryDataParameters<BundleQueryVariables, BundleQuery>) => {
    return useQueryData({
        query: getBundleQueryData,
        ...params,
    });
};

const getBikeFinderQueryData = async (variables: BikefinderQueryVariables): Promise<ExtendedApolloQueryResult<BikefinderQuery>> => {
    return await getQueryData(variables, '/api/bike-finder');
};

export const useBikeFinderQueryData = (params: UseQueryDataParameters<BikefinderQueryVariables, BikefinderQuery>) => {
    return useQueryData({
        query: getBikeFinderQueryData,
        ...params,
    });
};

const getShopContentQueryData = async (variables: ShopContentQueryVariables): Promise<ExtendedApolloQueryResult<ShopContentQuery>> => {
    return await getQueryData(variables, '/api/shop-content');
};

export const useShopContentQueryData = (params: UseQueryDataParameters<ShopContentQueryVariables, ShopContentQuery>) => {
    return useQueryData({
        query: getShopContentQueryData,
        ...params,
    });
};

const getRelatedQueryData = async (variables: RelatedQueryVariables): Promise<ExtendedApolloQueryResult<RelatedQuery>> => {
    return await getQueryData(variables, '/api/related');
};

export const useRelatedQueryData = (params: UseQueryDataParameters<RelatedQueryVariables, RelatedQuery>) => {
    return useQueryData({
        query: getRelatedQueryData,
        ...params,
    });
};

const getTeaserQueryData = async (variables: TeaserQueryVariables): Promise<ExtendedApolloQueryResult<TeaserQuery>> => {
    return await getQueryData(variables, '/api/teaser');
};

export const useTeaserQueryData = (params: UseQueryDataParameters<TeaserQueryVariables, TeaserQuery>) => {
    if (typeof params.variables.filters?.limit === 'number' && params.variables.filters?.limit <= 0) {
        // we emulate
        if (params.callback) {
            params.callback({
                data: {
                    fetchTeaser: {
                        teaser: [],
                        references: [],
                        refs: {
                            links: [],
                            tags: [],
                            authors: [],
                            references: [],
                        },
                    },
                },
                loading: false,
                networkStatus: 7,
            });
        }
        return {
            loading: ref(false),
            result: ref(null),
        };
    } else {
        return useQueryData({
            query: getTeaserQueryData,
            ...params,
        });
    }
};

type QueryType<Variables, Query> = (variables: Variables) => Promise<ExtendedApolloQueryResult<Query>>;

type QueryReturn<Variables, Query> = {
    variables: Variables;
    loading: Ref<boolean>;
    result: Ref<ExtendedApolloQueryResult<Query> | null>;
    error: Ref<Error | null>;
    promise: Promise<ExtendedApolloQueryResult<Query> | null>;
    asyncData: AsyncData<ExtendedApolloQueryResult<Query> | null, Error | null>;
};

export const generateCacheKeyForVariables = <QueryVariables extends OperationVariables>(variables: QueryVariables | undefined) => {
    const variablesValue = variables || {};

    const variablePairs = Object.keys(variablesValue).map((key) => {
        // @ts-ignore
        const variableValue = Object.hasOwn(variablesValue, key) && !!variablesValue[key] ? JSON.stringify(variablesValue[key]) : '';
        return {
            key,
            value: variableValue,
        };
    });

    const variablesSorted = variablePairs.sort((a, b) => {
        return a.key < b.key ? -1 : 1;
    });

    const variablesString = variablesSorted
        .map((variable) => {
            return `${variable.key}=${variable.value}`;
        })
        .join('|');

    return variablesString;
};

export const useQueryData = <Variables extends OperationVariables, Query>(
    params: UseQueryDataParameters<Variables, Query> & { query: QueryType<Variables, Query> },
): QueryReturn<Variables, Query> => {
    const { query, variables, callback, onErr } = params;
    // const loading = ref(false);
    const error: Ref<Error | null> = ref(null);
    const result: Ref<ExtendedApolloQueryResult<Query> | null> = ref(null);
    const cacheKey = `${query.name}|${generateCacheKeyForVariables(variables)}`;

    // const promise: AsyncData<PickFrom<Query, KeysOf<Query>> | null, Error | null>
    const asyncData = useAsyncData<ExtendedApolloQueryResult<Query>>(cacheKey, () => query(variables));

    //<PickFrom<ExtendedApolloQueryResult<Query>, KeysOf<ExtendedApolloQueryResult<Query>>> | null>

    const promise = new Promise<ExtendedApolloQueryResult<Query> | null>((resolve, reject) => {
        asyncData
            .then((newAsyncData) => {
                const payload = newAsyncData.data.value;

                result.value = payload;
                error.value = null;
                if (callback) {
                    callback(payload);
                }
                resolve(payload);
            })
            .catch((err) => {
                error.value = err;
                if (onErr) {
                    onErr(err);
                }
                reject(err);
            });
    });

    return {
        variables,
        loading: asyncData.pending,
        result,
        error,
        promise,
        asyncData,
    };
};

export const doCheckoutMutation = (variables: CheckoutMutationVariables): Promise<Awaited<MutateResult<CheckoutMutation>> | null | undefined> => {
    const authStore = useAuthStore();
    return $fetch
        .raw<MutateResult<CheckoutMutation> | null | undefined>('/api/checkout', {
            method: 'POST',
            body: JSON.stringify(variables),
        })
        .then((result) => {
            // console.log('result', result);

            const token = result.headers.get(WoomCookies.WoomSession);
            if (token) {
                authStore.setToken({
                    token,
                });
            }

            return result._data;
        });
};

export const doFindMyBikeMutation = async (variables: FindMyBikeMutationVariables): Promise<MutateResult<FindMyBikeMutation> | null | undefined> => {
    const result = await $fetch<MutateResult<FindMyBikeMutation> | null | undefined>('/api/find-my-bike', {
        method: 'POST',
        body: variables,
    });

    return result;
};

export const doBackInStockSubmitMutation = async (
    variables: BackInStockSubmitMutationVariables,
): Promise<MutateResult<BackInStockSubmitMutation> | null | undefined> => {
    const result = await $fetch<MutateResult<BackInStockSubmitMutation> | null | undefined>('/api/back-in-stock', {
        method: 'POST',
        body: variables,
    });

    return result;
};

export const doBackInStockKlaviyoMutation = async (
    variables: BackInStockMutationVariables,
): Promise<MutateResult<BackInStockMutation> | null | undefined> => {
    const result = await $fetch<MutateResult<BackInStockMutation> | null | undefined>('/api/klaviyo-back-in-stock', {
        method: 'POST',
        body: variables,
    });

    return result;
};
