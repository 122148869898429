export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    if (!process.server && config.public.klaviyo) {
        const locale = nuxtApp.$i18n?.locale?.value;
        if (locale && locale.toLowerCase().indexOf('_us') > 1) {
            const klaviyoGtmSubmit = (e: any) => {
                if (e.detail.type == 'submit') {
                    // ga('send', 'event', 'Klaviyo form', 'form_submit', e.detail.formId);
                    const data = window.klaviyoWoomCache?.[e.detail.formId];
                    if (data) {
                        // console.log('klaviyo sending event', data)
                        nuxtApp.$gtm?.pushEvent({
                            event: data.eventtype || 'form_submit',
                            form_id: data.code,
                            form_name: data.formevent || data.code,
                        });
                    } else {
                        nuxtApp.$gtm?.pushEvent({
                            event: 'form_submit',
                            form_id: e.detail.formId,
                            form_name: e.detail && e.detail.metadata && e.detail.metadata.source ? e.detail.metadata.source : e.detail.formId,
                        });
                    }
                }
            };
            useHead({
                script: [
                    {
                        src: `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${config.public.klaviyo}`,
                        async: true,
                        onload: () => {
                            if (window) {
                                window.woomKlaviyoInit = true;
                                document.dispatchEvent(new Event('woom-klaviyo-init'));
                                window.addEventListener('klaviyoForms', klaviyoGtmSubmit);
                            }
                        },
                    },
                ],
            });
        }
    }
});
