const queryKeys = ['preview', 'cache_update'];
export const usePreview = () => {
    const { enabled } = usePreviewMode();
    const { query } = useRoute();
    const queryParams = computed(() => {
        const params: Record<string, (typeof query)[string]> = {};
        queryKeys.forEach((key) => {
            const value = query[key];
            if (value) params[key] = value;
        });
        return params;
    });

    return {
        previewEnabed: enabled,
        queryParams,
    };
};
