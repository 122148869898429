import { EventEmitter } from 'events';
import type TypedEmitter from 'typed-emitter'; //, { EventMap }

export type OpenCartEventParams = { variant: unknown; product: any }; // UNKNOWN_TYPE

type WoomEventEmitterEvents = {
    'side-drawer': (data: Events.SideDrawerEventParams) => void;
    'open-video': (data: Events.VideoEventParams) => void;
    'clear-form': () => void;
    'on-navigation': () => void;
    'account-dialog': (data: Events.AccountDialogEventParams) => void;
    'global-dialog': (data: Events.GlobalDialogEventParams) => void;
    // form: (data: Events.FormEventParams) => void; // TODO
};

export type WoomEventEmitter = TypedEmitter<WoomEventEmitterEvents>;

export default defineNuxtPlugin((nuxtApp) => {
    const emitter = new EventEmitter() as WoomEventEmitter;

    nuxtApp.provide('eventEmitter', emitter);
});
