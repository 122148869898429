/* eslint-disable prefer-rest-params */
/**
 * webgains plugin
 *  - check in the website where the method calls are happening
 */

export interface WebgainsPluginOptions {
    id: string;
    layer: string;
    pageTracking?: boolean;
    dev?: boolean;
    respectDoNotTrack?: boolean;
}

// declare module '@nuxt/schema' {
//     interface NuxtConfig {
//         webgains?: WebgainsPluginOptions;
//     }
//     interface NuxtOptions {
//         webgains?: WebgainsPluginOptions;
//     }
// }

declare module 'nuxt/schema' {
    interface RuntimeConfig {
        webgains: WebgainsPluginOptions;
    }
}

class Webgains extends TrackerClass<WebgainsPluginOptions> {
    #firstCall = false;
    #allowed = false;
    override name = 'webgains';

    init() {
        if (this.options.pageTracking && (!this.options.respectDoNotTrack || !hasDNT())) {
            safeWindow.ITCLKOBJ = 'ITCLKQ';
            safeWindow.ITCLKQ =
                safeWindow.ITCLKQ ||
                function () {
                    (safeWindow.ITCLKQ.q = safeWindow.ITCLKQ.q || []).push(arguments);
                };
            safeWindow.ITCLKQ.l = new Date().getTime();

            // console.log(this.trackingLayer);

            this.trackingLayer.push({
                event: 'ntmInit',
                t: new Date().getTime(),
            });
            // console.log('do page tracking') // eslint-disable-line no-console
            this.#initPageTracking();
        }
    }

    pushEvent(obj: Record<string, any>) {
        if (!this.#allowed) return;
        this.pushEventSafe({
            gdpr: 1,
            gdprConsent: hasDNT() ? '1' : '0',
            ...obj,
        });
    }

    #initPageTracking() {
        const router = useRouter();
        router.afterEach((to, _from) => {
            if (to && to.params) {
                if (!this.#firstCall) {
                    this.#allowed = true;
                    this.#firstCall = true;
                }
                if (!to.params.slug) {
                    this.pushEvent({
                        pageType: 'homepage',
                    });
                } else {
                    this.pushEvent({
                        pageType: 'generic',
                        pageName: to.params.slug,
                    });
                }
            }
        });
    }
}
export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    // const options = config.webgains;
    const options: WebgainsPluginOptions = config.public?.webgains;
    if (config.public.nodeEnv !== 'production' && !options?.dev) return;

    const $webgains = new Webgains(options);
    nuxtApp.provide('webgains', $webgains);
    $webgains.init();
});
