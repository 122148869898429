export const useLocales = () => {
    const { locale, locales, t, te } = useI18n();

    const days = 60;

    watch(
        () => locale.value,
        () => {
            let expires = '';
            if (days) {
                const date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = '; expires=' + date.toUTCString();
            }
            document.cookie = 'lang=' + `${locale.value}` + expires + '; path=/';
        },
    );

    const appState = useAppStore();

    const matchingLocale = computed(() => {
        for (const localeItem of locales.value) {
            if (typeof localeItem === 'object') {
                if (locale.value === localeItem.code) {
                    return localeItem;
                }
            }
        }
    });

    const isoCode = computed(() => {
        const code = matchingLocale.value?.code.replace(/_/g, '-').toLowerCase();
        return code || 'de-de';
    });

    const isoLocale = computed(() => {
        return matchingLocale.value?.iso || 'de';
    });

    const iso2Lang = computed(() => {
        return matchingLocale.value?.iso?.substring(0, 2) || 'de';
    });

    const iso2Country = computed<string>(() => {
        return matchingLocale.value?.countryISO2 || 'DE';
    });

    let metric: boolean;
    let imperial: boolean;
    const units = appState.website?.shop?.units;
    if (!units) {
        metric = true;
        imperial = true;
    } else {
        metric = units.includes('metric');
        imperial = units.includes('imperial');
    }

    const d = (d: string | Date): string => {
        const date = typeof d === 'string' ? Date.parse(d) : d;
        return new Intl.DateTimeFormat(isoLocale.value).format(date);
    };

    const languageName = computed(() => getLanguageNativeName(iso2Lang.value));

    const isUS = ref(iso2Country.value.toLowerCase() === 'us');

    return {
        locale,
        locales,
        localeSuffix: locale.value.substring(locale.value.indexOf('_') + 1),
        t, // translation
        te, // translation exists
        d, // date formatting
        n: (num: number) => {
            return new Intl.NumberFormat(isoLocale.value, { maximumSignificantDigits: 2 }).format(num);
        },
        m: (num: number, currencyCode: string) => {
            return new Intl.NumberFormat(isoLocale.value, {
                style: 'currency',
                currency: currencyCode,
            }).format(num);
        },
        isoCode,
        isoLocale,
        iso2Lang,
        iso2Country,
        languageName,
        isUS,
        flag: 'fi ' + `fi-${iso2Country.value.toLowerCase()}`,
        metric,
        imperial,
        isInternational: locale.value.toUpperCase().indexOf('_INT') > 1,
    };
};

export const useLocalePathWoom = () => {
    const localePath = useLocalePath();

    return (...props: Parameters<typeof localePath>) => {
        const path = props[0];
        let route = localePath(...props);
        if (path === '/' || (typeof path === 'object' && path.path === '/')) {
            route += '/';
        }
        return route;
    };
};
