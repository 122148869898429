<script setup lang="ts">
    import imageError from '../../assets/images/404.jpg';

    declare interface ErrorProps {
        statusCode?: string | number;
        url?: string;
    }

    const props = withDefaults(defineProps<ErrorProps>(), {
        statusCode: '404',
        url: '/',
    });
    const nuxtApp = useNuxtApp();

    useHead({
        meta: [
            { 'http-equiv': 'cache-control', content: 'max-age=0' },
            { 'http-equiv': 'cache-control', content: 'no-cache' },
            { 'http-equiv': 'expires', content: '0' },
            { 'http-equiv': 'expires', content: 'Tue, 01 Jan 1980 1:00:00 GMT' },
            { 'http-equiv': 'pragma', content: 'no-cache' },
        ],
    });

    if (nuxtApp.ssrContext) {
        nuxtApp.ssrContext.event.node.res.statusCode = parseInt(`${props.statusCode}`);
    }
</script>
<template>
    <atm-grid
        as="section"
        class="mol-error mt-10 lg:mt-12">
        <div class="col-span-12 flex flex-col items-center text-center lg:col-span-4 lg:justify-center">
            <div class="flex max-w-xl flex-col gap-5">
                <atm-heading
                    level="h1"
                    size="2xl">
                    {{ statusCode }}
                </atm-heading>
                <p>{{ $te(`error.${statusCode}`) ? $t(`error.${statusCode}`) : $t('error.5xx') }}</p>
                <atm-button
                    :to="url"
                    kind="link"
                    styling="solid-secondary"
                    class="mx-auto mt-4">
                    {{ $t('error.homepage') }}
                </atm-button>
            </div>
        </div>
        <div class="col-span-12 lg:col-span-7">
            <atm-image :src="imageError" />
        </div>
    </atm-grid>
    <div class="hidden">ERROR-PAGE</div>
</template>
