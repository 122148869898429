import routerOptions0 from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_azptlvd4a7osyu6kcvbtwnweam/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/usr/src/app/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}