<script setup lang="ts">
    import { NuxtLink } from '#components';

    export type ButtonStyling =
        | 'solid'
        | 'solid-secondary'
        | 'solid-invert'
        | 'outline'
        | 'outline-invert'
        | 'text'
        | 'text-secondary'
        | 'text-invert'
        | 'solid-custom'
        | 'outline-custom';

    export interface ButtonProps {
        kind?: 'link' | 'button' | 'decorative';
        to?: string | never;
        target?: '_self' | '_blank';
        styling?: ButtonStyling;
        size?: 'small' | 'medium';
        iconRight?: boolean;
        block?: boolean;
        loading?: boolean;
        disabled?: boolean;
        colorLabel?: string;
        colorBg?: string;
        noGtm?: boolean;
        skipLocalPath?: boolean;
        gtm?: any;
    }

    const props = withDefaults(defineProps<ButtonProps>(), {
        kind: 'button',
        to: '',
        target: '_self',
        styling: 'solid',
        size: 'medium',
        gtm: {},
        colorbg: '#EB0613',
        colorLabel: '#FFFFFF',
    });

    const slots = useSlots();

    const emit = defineEmits<{
        (e: 'click', data: MouseEvent): void;
    }>();

    const customStyles = computed(() =>
        ['solid-custom', 'outline-custom'].includes(props.styling)
            ? {
                  '--custom-bg': props.colorBg,
                  '--custom-label': props.colorLabel,
              }
            : {},
    );

    const handleClick = (evt: MouseEvent) => {
        emit('click', evt);
    };

    const kindType = computed(() => {
        if (props.kind === 'link') return NuxtLink;
        if (props.kind === 'decorative') return 'span';
        return 'button';
    });
</script>

<template>
    <component
        :is="kindType"
        :to="kind === 'link' ? $helpers.generateLocalePath(to, skipLocalPath) : undefined"
        :tartget="target"
        :disabled="disabled || loading"
        :class="[
            'atm-button relative inline-flex items-center justify-center gap-2 rounded-md px-4 text-center text-base font-bold transition-colors max-md:w-full md:max-w-96',
            {
                'h-10 text-xs': size === 'small',
                'h-12': size === 'medium',
                'hover:underline': !disabled && ['text', 'text-secondary', 'text-invert'].includes(styling),
                'text-woom-red': styling === 'text-secondary',
                'text-woom-white': styling === 'text-invert',
                'bg-woom-red text-woom-white': styling === 'solid',
                'hover:bg-woom-red-hover': !disabled && styling === 'solid',
                'bg-woom-black text-woom-white ': styling === 'solid-secondary',
                'hover:bg-black': !disabled && styling === 'solid-secondary',
                'bg-[--custom-bg] text-[--custom-label]': styling === 'solid-custom',
                'bg-woom-white text-woom-black': styling === 'solid-invert',
                'hover:bg-woom-grey-mid': (!disabled && styling === 'solid-invert') || (!disabled && styling === 'outline'),
                'border-2 border-solid border-woom-black text-woom-black ': styling === 'outline',
                'border-2 border-solid border-woom-white text-woom-white': styling === 'outline-invert',
                'hover:bg-woom-grey-moon': !disabled && styling === 'outline-invert',
                'border-2 border-solid border-[--custom-bg] text-[--custom-label]': styling === 'outline-custom',
                'flex-row-reverse': iconRight,
                'w-full !max-w-none': block,
                'pointer-events-none bg-opacity-40': disabled,
            },
        ]"
        :style="customStyles"
        @click="handleClick">
        <atm-loader-circular
            v-show="loading"
            :class="['absolute', { hidden: !loading }]" />

        <span
            v-if="slots.icon"
            :class="['fill-current', { 'opacity-0': loading }]"
            :aria-hidden="loading">
            <slot name="icon"></slot>
        </span>
        <span
            :class="['w-auto overflow-hidden text-ellipsis whitespace-nowrap text-center', { 'opacity-0': loading }]"
            :aria-hidden="loading">
            <slot></slot>
        </span>
    </component>
</template>

<style scoped>
    button :slotted(.icon-svg) {
        @apply h-5 w-5 flex-shrink-0 fill-current;
    }
</style>
