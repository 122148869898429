import { default as _91_46_46_46slug_93pvGVZEAWcBMeta } from "/usr/src/app/pages/[...slug].vue?macro=true";
import { default as index6LTnG94DlyMeta } from "/usr/src/app/pages/account/index.vue?macro=true";
import { default as _91_46_46_46slug_93qFOjsfYCNVMeta } from "/usr/src/app/pages/cart/[...slug].vue?macro=true";
import { default as component_45stubXBkUW5AgHoMeta } from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_azptlvd4a7osyu6kcvbtwnweam/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubXBkUW5AgHo } from "/usr/src/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_eslint@8.57.0_ioredis@5.4.1_magicast@0_azptlvd4a7osyu6kcvbtwnweam/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___de_AT",
    path: "/de_AT/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de_DE",
    path: "/de_DE/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___de_CH",
    path: "/de_CH/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr_CH",
    path: "/fr_CH/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en_GB",
    path: "/en_GB/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en_US",
    path: "/en_US/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr_FR",
    path: "/fr_FR/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___sv_SE",
    path: "/sv_SE/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___da_DK",
    path: "/da_DK/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___nl_NL",
    path: "/nl_NL/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en_INT",
    path: "/en_INT/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "account___de_AT",
    path: "/de_AT/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de_DE",
    path: "/de_DE/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de_CH",
    path: "/de_CH/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___fr_CH",
    path: "/fr_CH/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en_GB",
    path: "/en_GB/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en_US",
    path: "/en_US/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___fr_FR",
    path: "/fr_FR/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___sv_SE",
    path: "/sv_SE/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___da_DK",
    path: "/da_DK/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___nl_NL",
    path: "/nl_NL/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en_INT",
    path: "/en_INT/account",
    meta: index6LTnG94DlyMeta || {},
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-slug___de_AT",
    path: "/de_AT/cart/:slug(.*)*",
    component: () => import("/usr/src/app/pages/cart/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cart-slug___de_DE",
    path: "/de_DE/cart/:slug(.*)*",
    component: () => import("/usr/src/app/pages/cart/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cart-slug___de_CH",
    path: "/de_CH/cart/:slug(.*)*",
    component: () => import("/usr/src/app/pages/cart/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cart-slug___fr_CH",
    path: "/fr_CH/cart/:slug(.*)*",
    component: () => import("/usr/src/app/pages/cart/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cart-slug___en_GB",
    path: "/en_GB/cart/:slug(.*)*",
    component: () => import("/usr/src/app/pages/cart/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cart-slug___en_US",
    path: "/en_US/cart/:slug(.*)*",
    component: () => import("/usr/src/app/pages/cart/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cart-slug___fr_FR",
    path: "/fr_FR/cart/:slug(.*)*",
    component: () => import("/usr/src/app/pages/cart/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cart-slug___sv_SE",
    path: "/sv_SE/cart/:slug(.*)*",
    component: () => import("/usr/src/app/pages/cart/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cart-slug___da_DK",
    path: "/da_DK/cart/:slug(.*)*",
    component: () => import("/usr/src/app/pages/cart/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cart-slug___nl_NL",
    path: "/nl_NL/cart/:slug(.*)*",
    component: () => import("/usr/src/app/pages/cart/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cart-slug___en_INT",
    path: "/en_INT/cart/:slug(.*)*",
    component: () => import("/usr/src/app/pages/cart/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubXBkUW5AgHoMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubXBkUW5AgHo
  }
]