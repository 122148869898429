export const safeWindow = window as any;

export function hasDNT() {
    return (
        safeWindow.doNotTrack === '1' ||
        navigator.doNotTrack === 'yes' ||
        navigator.doNotTrack === '1' ||
        (navigator as any).msDoNotTrack === '1' ||
        (safeWindow.external && safeWindow.external.msTrackingProtectionEnabled && safeWindow.external.msTrackingProtectionEnabled())
    );
}

export const trackingLayer = (layer: string) => {
    if (!safeWindow[layer]) safeWindow[layer] = [];
    return safeWindow[layer];
};

export class TrackerClass<T extends { layer: string }> {
    options: T;
    trackingLayer: any[];
    name: string = 'TrackerClass';
    constructor(options: T) {
        this.options = options;
        this.trackingLayer = trackingLayer(this.options.layer);
    }

    pushEventSafe(obj: Record<string, any>) {
        try {
            if (!window || !this.trackingLayer) {
                throw new Error(`missing ${this.name} dataLayer`);
            }
            if (typeof obj !== 'object') {
                throw new TypeError('event should be an object');
            }
            this.trackingLayer.push(obj);
        } catch (err) {
            console.error(`[ERROR] [${this.name}]`, err);
        }
    }
}
